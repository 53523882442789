import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {IngredientEntityCommonNameViewModel} from "./ingredient-entity-common-name-view-model";
import {IngredientEntityFamilyViewModel} from "./ingredient-entity-family-view-model";

export interface IngredientEntityViewModelProps {
	title: string
	family: IngredientEntityFamilyViewModel | null
	commonNames: IngredientEntityCommonNameViewModel[]
}

export class IngredientEntityViewModel extends ViewModel<IngredientEntityViewModelProps> {

	private constructor(props: IngredientEntityViewModelProps) {
		super(props);
	}

	public static create(props: IngredientEntityViewModelProps): IngredientEntityViewModel {
		return new IngredientEntityViewModel({...props})
	}

	public localized_common_name_preferred(locale: string): string | null {
		if (this.props.commonNames) {
			const localized = this.props.commonNames.filter(name => name.props.locale === locale);

			if (localized.length > 0) {
				const preferred_name = localized.filter((name) => (name.props.locale === locale && name.props.isPreferred));

				if (preferred_name.length > 0) {
					return preferred_name[0].props.commonName
				} else {
					return localized[0].props.commonName
				}
			} else {
				return null;
			}

		} else {
			return null;
		}
	}

	public japanese_common_name_preferred(): string | null {
		if (this.props.commonNames) {
			const localized = this.props.commonNames.filter(name => name.props.locale === 'ja');

			if (localized.length > 0) {
				const preferred_name = localized.filter((name) => (name.props.locale === 'ja' && name.props.script === 'latin' && name.props.isPreferred));

				if (preferred_name.length > 0) {
					return preferred_name[0].props.commonName
				} else {
					return localized[0].props.commonName
				}
			} else {
				return null;
			}

		} else {
			return null;
		}
	}

}
