import React from "react";


const IngredientBodyParagraph = (props: {
	children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
	return (
		<p className="mb-3 text-base">
			{props.children}
		</p>
	)
}

export default IngredientBodyParagraph;
