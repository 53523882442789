import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import IngredientSeasonCard from "./card/ingredient-season-card";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";
import {useI18next} from "gatsby-plugin-react-i18next";
import {IngredientSeasonViewModel} from "../../model/ingredient-season-view-model";


export interface BestSeasonCalendarProps {
    viewModel: IngredientAggregateViewModel
}

export default function BestSeasonCalendar({viewModel}: BestSeasonCalendarProps) {

    const {t, language} = useI18next();

    const sort_items = (items: IngredientSeasonViewModel[]) => {
        if (items.length > 1) {
            return items.sort((a, b) => {
                const a_names = a.props.entity?.localized_common_name_preferred(language) ?? "";
                const b_names = b.props.entity?.localized_common_name_preferred(language) ?? "";
                return (a_names < b_names ? -1:1)
            })
        } else {
            return items;
        }
    }

    if (viewModel.props.seasons.length > 0) {
        return (
            <>
                <IngredientContentBodyHeadingH2 id={'season-calendar'}>
                    {t("Ingredients.BestSeasonCalendar.title", {name: viewModel.props.title})}
                </IngredientContentBodyHeadingH2>
                {/* Items */}
                {sort_items(viewModel.props.seasons)
                    .map((item, index) => {
                    return (
                       <IngredientSeasonCard item={item} key={index} index={index} maxIndex={viewModel.props.seasons.length-1}/>
                    )
                })}
            </>
        )
    } else {
        return (<></>)
    }


}