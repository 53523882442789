import {graphql, useStaticQuery} from "gatsby"
import {ArticleJsonLd, BreadcrumbJsonLd, FAQJsonLd, GatsbySeo} from "gatsby-plugin-next-seo";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import metaPublisherJson from "../../../../../../shared/seo/presentation/seo-meta-publisher";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {toKatakana} from "wanakana";

const getLogoUrl = () => {
    const data = useStaticQuery(graphql`
		{
		  file(name: {eq: "sushi-pedia-logo"}) {
			publicURL
		  }
		}
	  `)
    return data.file.publicURL
}


export interface IngredientSeoProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientSeo({viewModel}: IngredientSeoProps) {

    const {language, t} = useI18next();
    const slug = viewModel.props.slug;
    const localizedTranslation = viewModel.localized_translation(language);
    const metaTags = metaPublisherJson()
    const url = `${t("Site.url")}/${language}/sushi/${viewModel.props.slug}`;

    const images = (): string[] => {
        if (viewModel.props.images.opener.length > 0) {
            return [t("Site.url") + viewModel.props.images.opener[0].node.publicURL]
        } else {
            return []
        }
    }

    const languageAlternates = viewModel.available_translation_locales.map((locale) => {
        return {
            hrefLang: locale,
            href: t("Site.url") + "/" + locale + "/sushi/" + slug,
        }
    })

    const JsonLdArticle = () => (
        <>
            <ArticleJsonLd
                url={url}
                headline={`${viewModel.props.title} Sushi`}
                images={images()}
                datePublished={localizedTranslation.props.dates.created.toString()}
                dateModified={localizedTranslation.props.dates.updated?.toString()}
                authorName='Sushipedia'
                publisherName='Sushipedia'
                publisherLogo={t("Site.url") + getLogoUrl()}
                description={articleText()}
                // overrides={{
                // 	'@type': 'BlogPosting', // set's this as a blog post.
                // }}
            />
        </>
    )

    const JsonLdBreadcrumb = () => (
        <>
            <BreadcrumbJsonLd
                itemListElements={[
                    {
                        position: 1,
                        name: t("Ingredients.Breadcrumbs.home"),
                        item: `${t("Site.url")}/${language}`,
                    },
                    {
                        position: 2,
                        name: t("Ingredients.Breadcrumbs.ingredients"),
                        item: `${t("Site.url")}/${language}/sushi`,
                    },
                    {
                        position: 3,
                        name: `${viewModel.props.title} Sushi`,
                        item: url,
                    }
                ]}
            />
        </>
    )

    const articleText = () => {
        return localizedTranslation.props.content.definition + " " +
            localizedTranslation.props.content.as_ingredient_for_sushi_sashimi + " " +
            localizedTranslation.props.content.in_japan + " " +
            localizedTranslation.props.content.trivia + " " +
            localizedTranslation.props.content.characteristics_and_ecology + " " +
            localizedTranslation.props.content.economy;
    }

    const metaTitle = () => {
        const title = viewModel.props.title;
        const commonName = viewModel.localized_main_entity_common_name_preferred(language);
        const kanji = viewModel.japanese_main_entity_kanji_preferred();
        return `${viewModel.props.title} Sushi ${commonName ? `〚 ${commonName} 〛` : ""} 【${kanji ? kanji : toKatakana(title)}】 (Information) - Sushipedia`
    }

    return (
        <>
            <GatsbySeo
                language={language}
                title={metaTitle()}
                description={localizedTranslation.props.meta.description}
                languageAlternates={languageAlternates}
                metaTags={metaTags}
                canonical={url}
            />
            <JsonLdBreadcrumb/>
            <JsonLdArticle/>
        </>
    )
}

