import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import IngredientContentBodySectionDefinition
    from "../components/definition/ingredient-content-body-section-definition";
import IngredientContentBodySectionEcology
    from "../components/ecology/ingredient-content-body-section-ecology";
import IngredientContentBodySectionEconomy from "../components/economy/ingredient-content-body-section-economy";
import IngredientContentBodySectionJapan
    from "../components/japan/ingredient-content-body-section-japan";
import IngredientContentBodySectionSushiSashimi
    from "../components/sushi-sashimi/ingredient-content-body-section-sushi-sashimi";
import IngredientContentBodySectionTrivia
    from "../components/trivia/ingredient-content-body-section-trivia";
import IngredientImageOpener from "../components/opener-image/ingredient-image-opener";
import IngredientGallery from "../components/gallery/ingredient-gallery";
import {
    IngredientGalleryImageCredits
} from "../components/gallery/ingredient-gallery-image-credits";
import {IngredientSources} from "../components/source/ingredient-sources";
import {IngredientAggregateViewModel} from "../model/ingredient-aggregate-view-model";
import {IngredientWarnings} from "../components/warnings/ingredient-warnings";
import {IngredientHeading} from "../components/heading/ingredient-heading";
import IngredientImageDistributionMap
    from "../components/distribution/ingredient-image-distribution-map";
import IngredientSectionEntities from "../components/entities/ingredient-section-entities";
import IngredientContentVideo from "../components/video/ingredient-content-video";
import IngredientContentTypes from "../components/types/ingredient-content-types";
import IngredientAuthorDates
    from "../components/author_dates/ingredient-author-dates";
import IngredientBreadcrumbs from "../components/breadcrumbs/ingredient-breadcrumbs";
import BestSeasonCalendar from "../components/best-season/best-season-calendar";


export type IngredientPageContentProps = {
    ingredientAggregate: IngredientAggregateViewModel
}

export default function IngredientPageContent({ingredientAggregate}: IngredientPageContentProps) {

    // i18n
    const {t, language} = useI18next();

    const {title} = ingredientAggregate.props;


    return (
        <>
            {/* Container */}
            <div className="layout-containerized font-sans">

                {/* Grid */}
                <div className="grid grid-cols-12 gap-2">

                    {/* Main (Left Grid) */}
                    <div className={"col-span-12 lg:col-span-12"}>
                        <div className="flex flex-col py-3 px-3">

                            {/* Breadcrumbs */}
                            <IngredientBreadcrumbs viewModel={ingredientAggregate}/>

                            <IngredientAuthorDates viewModel={ingredientAggregate}/>

                            {/* Heading */}
                            <div className="">
                                <IngredientHeading viewModel={ingredientAggregate}/>
                            </div>


                            {/* Opener Image */}
                            <div>
                                <IngredientImageOpener viewModel={ingredientAggregate}/>
                            </div>

                            {/* Content */}
                            <div className="flex flex-col">


                                <IngredientContentBodySectionDefinition viewModel={ingredientAggregate}/>
                                <IngredientContentBodySectionSushiSashimi viewModel={ingredientAggregate}/>


                                {/*<BestSeasonCalendar viewModel={ingredientAggregate}/>*/}


                                <IngredientContentTypes viewModel={ingredientAggregate}/>

                                <IngredientContentBodySectionJapan viewModel={ingredientAggregate}/>
                                <IngredientContentBodySectionTrivia viewModel={ingredientAggregate}/>
                                <IngredientContentBodySectionEcology viewModel={ingredientAggregate}/>
                                <IngredientContentBodySectionEconomy viewModel={ingredientAggregate}/>

                                <BestSeasonCalendar viewModel={ingredientAggregate}/>

                                {/* Gallery */}
                                <IngredientGallery viewModel={ingredientAggregate}/>

                                {/* Video */}
                                <IngredientContentVideo viewModel={ingredientAggregate}/>

                                {/* Distribution Map*/}
                                <IngredientImageDistributionMap viewModel={ingredientAggregate}/>

                                {/* Warning */}
                                <IngredientWarnings viewModel={ingredientAggregate}/>

                                {/* Entities */}
                                <IngredientSectionEntities viewModel={ingredientAggregate}/>

                                {/* Sources */}
                                <IngredientSources viewModel={ingredientAggregate}/>

                                {/* Image Source */}
                                <IngredientGalleryImageCredits viewModel={ingredientAggregate}/>

                            </div>


                            {/*<pre className="bg-white">{JSON.stringify(ingredientAggregate, null, 4)}</pre>*/}

                        </div>
                    </div>


                    {/* Infobox (Right Grid*/}
                    <div className={"col-span-12 lg:col-span-3"}>
                        {/*<IngredientInfobox viewModel={ingredientAggregate}/>*/}
                    </div>
                </div>


            </div>
        </>
    )

}
