import {useI18next} from "gatsby-plugin-react-i18next";

export function titleCase(str: string | null | undefined): string | null {
    const {language} = useI18next();

    if (str) {
        if (language == "en") {
            const splitStr = str.toLowerCase().split(' ');
            for (let i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            // Directly return the joined string
            return splitStr.join(' ');
        } else {
            return str;
        }
    } else {
        return null;
    }
}

export function capitalizeFirstLetter(input: string | null | undefined): string | null {
    if (input) {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }
    return null;
}