import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import {ChevronRightIcon} from "@heroicons/react/outline";

export interface IngredientBreadcrumbsProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientBreadcrumbs({viewModel}: IngredientBreadcrumbsProps) {
    const {t} = useI18next();

    return (
        <>
            <nav className="w-full">
                <ol className="list-reset flex text-xs sm:text-base items-center">
                    {/* Ingredients */}
                    <li>
                        <a href={t("Site.sushi_ingredients_uri")} className="text-noriGreen hover:text-salmonRed">
                            {t("Ingredients.Breadcrumbs.ingredients")}
                        </a>
                    </li>

                    {/* Chevron */}
                    <li>
                        <ChevronRightIcon className="text-noriGreen h-4"/>
                    </li>

                    {/* Ingredient */}
                    <li className="align-middle text-gray-500">{viewModel.props.title} </li>
                </ol>
            </nav>
        </>
    )
}