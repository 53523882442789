import React from "react";
import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {ImagesContentEdge, ImagesGalleryMetaEdge} from "../../infrastructure/dto/ingrdient-graphql-response";

export interface IngredientGalleryImageViewModelProps {
	image_edge_full: any
	image_edge_small: any
	context: {
		alt: {
			de: string | null
			es: string | null
			en: string | null
		}
		original: {
			title: string | null
			source: {
				url: string | null,
				provider: string | null
			}
			changes: string[]
		}
		photographer: {
			name: string | null
			url: string | null
		},
		license: {
			name: string | null
			url: string | null
		}
	}
}

export class IngredientGalleryImageViewModel extends ViewModel<IngredientGalleryImageViewModelProps> {

	private constructor(props: IngredientGalleryImageViewModelProps) {
		super(props);
	}

	public static create(props: IngredientGalleryImageViewModelProps): IngredientGalleryImageViewModel {
		return new IngredientGalleryImageViewModel({...props})
	}

	public static create_from_graphql_edge(
		edge_image: ImagesContentEdge,
		edge_image_small: ImagesContentEdge,
		edge: ImagesGalleryMetaEdge
	): IngredientGalleryImageViewModel {
		const meta = edge.node.frontmatter;
		const props: IngredientGalleryImageViewModelProps = {
			image_edge_full: edge_image,
			image_edge_small: edge_image_small,
			context: {
				alt: {
					de: meta.photo_alt_de,
					en: meta.photo_alt_en,
					es: meta.photo_alt_es
				},
				license: {
					name: meta.photo_license,
					url: meta.photographer_url
				},
				original: {
					changes: meta.photo_change_tags ?? [],
					source: {
						provider: meta.photo_original_source_provider,
						url: meta.photo_original_source_url
					},
					title: meta.photo_original_title
				},
				photographer: {
					name: meta.photographer_name,
					url: meta.photographer_url
				}
			}

		}
		return new IngredientGalleryImageViewModel(props);
	}

	public generateStringCite(): string {
		const photographerName = this.props.context.photographer.name ?? "";
		const imageTitle = this.props.context.original.title ?? "";
		const imageSource = this.props.context.original.source.provider ?? "";
		const imageLicense = this.props.context.license.name ?? "";

		return `
				${photographerName ? photographerName + ". " : ""}
				${imageTitle ? imageTitle + ". " : ""}
				${imageSource ? imageSource + ". " : ""}
				${imageLicense ? imageLicense + ". " : ""}
				`
	}

	public getAlt(language: string): string | null {
		if (language == "de") {
			return this.props.context.alt.de;
		}
		if (language == "en") {
			return this.props.context.alt.en;
		}
		if (language == "es") {
			return this.props.context.alt.es;
		}
		return null;
	}

}

