import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import IngredientContentBodyParser from "../content/parser/ingredient-content-body-parser";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";


export interface IngredientContentBodyDefinitionProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientContentBodySectionDefinition({viewModel}: IngredientContentBodyDefinitionProps) {

    const {t, language} = useI18next();
    const localized_translation = viewModel.localized_translation(language);
    const body = localized_translation.props.content.definition;
    const images = viewModel.props.images.content

    function kanji() {
        const kanji_from_main_entity = viewModel.japanese_main_entity_kanji_preferred()
        const kanji_from_authentic_entity = viewModel.japanese_entities_authentic_kanji()

        if (kanji_from_main_entity) {
            return kanji_from_main_entity
        } else if (kanji_from_authentic_entity) {
            return kanji_from_authentic_entity
        } else {
            return null
        }
    }

    if (body) {
        return (
            <>
                <IngredientContentBodyHeadingH2 id={"definition"}>
                    {t("Ingredients.Content.definition", {variable: viewModel.localized_title_with_common_name(language)})}?
                </IngredientContentBodyHeadingH2>
                <div>
                    <IngredientContentBodyParser htmlInput={body} contentImageNodes={images}/>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
}

