import {IngredientEntityViewModel} from "../../../../model/ingredient-entity-view-model";
import {toKatakana} from "wanakana";
import {useI18next} from "gatsby-plugin-react-i18next";
import {ArrowSmDownIcon, ArrowSmUpIcon} from "@heroicons/react/outline";
import React from "react";
import {romaji_to_kana} from "../../../../../../../../shared/helper/romaji_parser";

export function build_romaji_names(input: IngredientEntityViewModel): string {
    return input.props.commonNames
        .filter((name) => (name.props.locale === "ja" && (name.props.script === "latin")))
        .sort((a, b) => (a.props.commonName < b.props.commonName) ? -1 : 1)
        .sort((a, b) => (a.props.isPreferred > b.props.isPreferred) ? -1 : 1)
        .map((name) => name.props.commonName)
        .slice(0, 2)
        .join(", ")
}

export function build_kana_names(input: IngredientEntityViewModel): string {
    return input.props.commonNames
        .filter((name) => (name.props.locale === "ja" && (name.props.script === "latin")))
        .sort((a, b) => (a.props.commonName < b.props.commonName) ? -1 : 1)
        .sort((a, b) => (a.props.isPreferred > b.props.isPreferred) ? -1 : 1)
        .map((name) => {
            // Remove whitespace and hyphen because they are not part of the japanese name
            return romaji_to_kana(name.props.commonName)
        })
        .slice(0, 2)
        .join("、")
}

export function build_kanjis(input: IngredientEntityViewModel): string {
    return input.props.commonNames
        .filter((name) => (name.props.locale === "ja" && (name.props.script === "kanji")))
        .sort((a, b) => (a.props.sort < b.props.sort) ? -1 : 1)
        .map((kanji) => kanji.props.commonName)
        .slice(0, 5)
        .join("、")
}

export function build_common_names(input: IngredientEntityViewModel): string {
    const {language} = useI18next();
    return input.props.commonNames
        .filter((name) => (name.props.locale === language && (name.props.script === "latin")))
        .sort((a, b) => (a.props.commonName < b.props.commonName) ? -1 : 1)
        .map((name) => name.props.commonName)
        .slice(0, 3)
        .join(", ")
}

export function build_family_common_names(input: IngredientEntityViewModel): string | null {
    if (input.props.family) {
        return input.props.family.props.title
    } else {
        return null;
    }
}


export function buildSortedLabel(label: string, isSorted: boolean, isSortedDesc: boolean) {
    if (isSorted) {
        if (isSortedDesc) {
            return (
                <div className="flex">
                <div>{label}</div>
                <div><ArrowSmDownIcon className="h-5"/></div>
                    </div>
            )
        } else {
            return (
                <div className="flex">
                <div>{label}</div>
                <div><ArrowSmUpIcon className="h-5"/></div>
                    </div>
            )
        }
    } else {
        return (<span> {label} </span>)
    }
}