import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import IngredientContentBodyParser from "../content/parser/ingredient-content-body-parser";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";

export interface IngredientContentBodySushiSashimiProps {
	viewModel: IngredientAggregateViewModel
}

export default function IngredientContentBodySectionSushiSashimi({viewModel}: IngredientContentBodySushiSashimiProps) {

	const {t, language} = useI18next();
	const localized_translation = viewModel.localized_translation(language);
	const body = localized_translation.props.content.as_ingredient_for_sushi_sashimi;
	const images = viewModel.props.images.content

	if (body) {
		return (
			<>
				<IngredientContentBodyHeadingH2 id={"definition"}>
					{t("Ingredients.Content.as_sushi", {variable: viewModel.props.title})}
				</IngredientContentBodyHeadingH2>
				<div>
					<IngredientContentBodyParser htmlInput={body} contentImageNodes={images}/>
				</div>
			</>
		)
	} else {
		return (<></>)
	}
}
