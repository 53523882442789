import {IngredientEntityViewModel} from "../../../../model/ingredient-entity-view-model";
import {useSortBy, useTable} from "react-table";
import React from "react";

interface IngredientEntityTableProps {
    columns: any,
    data: IngredientEntityViewModel[]
}

export function IngredientEntityTable({columns, data}: IngredientEntityTableProps) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns: columns,
            data: data,
            initialState: {
                sortBy: [
                    {
                        id: 'japanese_name',
                        desc: false
                    }
                ]
            }
        },
        useSortBy
    )

    return (
        <table className="mt-3 mb-7" {...getTableProps()}>

            <thead>
            {headerGroups.map((headerGroup) => (
                <tr className="border-b border-t" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                        <th className={`text-white text-left ${index % 2 == 0 ? "bg-[#9B0039]" : "bg-[#d70b58]"}`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                        </th>
                    ))}

                </tr>
            ))}
            </thead>
            <tbody className="border-b" {...getTableBodyProps()}>
            {rows.map((row, index) => {
                prepareRow(row)
                return (
                    <tr className={index % 2 == 0 ? "bg-gray-50" : "bg-gray-100"} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}
