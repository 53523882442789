import {GatsbyImage, getImage, IGatsbyImageData, ImageDataLike} from "gatsby-plugin-image";
import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {IngredientImageDistributionViewModel} from "../../model/ingredient-image-distribution";
import {capitalizeFirstLetter} from "../../../../../../shared/helper/title_case";

export interface IngredientImageDistributionMapProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientImageDistributionMap({viewModel}: IngredientImageDistributionMapProps) {
    const {t, language} = useI18next();

    const images = viewModel.props.images.distribution_maps

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    if (images.length > 0) {
        return (
            <div>
                <IngredientContentBodyHeadingH2 id={"gallery"}>
                    {t("Ingredients.DistributionMap.title", {name: viewModel.props.title})}
                </IngredientContentBodyHeadingH2>

                {images.length > 1 ?
                    <div className={`slider-wrapper pb-3`}>
                        <Slider {...settings}>
                            {images.map((image, index) => {
                                    return (
                                        <div key={index}>
                                            <MapImage viewModel={image}/>
                                        </div>
                                    )
                                }
                            )}
                        </Slider>
                    </div>
                    :
                    <>
                        <MapImage viewModel={images[0]}/>
                    </>
                }

                <div className="pb-3 pt-6">
                    <p className="text-xs">
                        {t("Ingredients.DistributionMap.source")}
                    </p>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}

export interface MapImageProps {
    viewModel: IngredientImageDistributionViewModel
}

function MapImage({viewModel}: MapImageProps): React.ReactElement {
    const {t, language} = useI18next();
    const gatsbyImageData = getImage(viewModel.props.imageEdge);
    if (gatsbyImageData) {
        return (
            <div className="rounded">
                <figure>
                    <GatsbyImage alt={t("Ingredients.DistributionMap.title", {name: viewModel.localizeCommonName(language)})}
                                 image={gatsbyImageData}/>
                </figure>
                {viewModel.props.meta.species &&
                    <figcaption className={"p-2 text-sm bg-slate-100 rounded-b-lg"}>
                        {capitalizeFirstLetter(viewModel.localizeCommonName(language))} (<i>{viewModel.props.meta.species}</i>)
                    </figcaption>
                }
            </div>
        )
    } else {
        return (<></>)
    }
}