import React from "react";


const IngredientContentBodyHeadingH4 = (props: {
	id: string | null,
	children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
	return (
		<h4 id={props.id ?? ``} className="text-base font-bold mb-1">
			{props.children}
		</h4>
	)
}

export default IngredientContentBodyHeadingH4;
