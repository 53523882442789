import {IngredientSeasonViewModel} from "../../../model/ingredient-season-view-model";
import {capitalizeFirstLetter} from "../../../../../../../shared/helper/title_case";
import React, {useEffect, useRef, useState} from "react";
import {useI18next} from "gatsby-plugin-react-i18next";
import {LibraryIcon} from "@heroicons/react/solid";
import {SourceCite} from "../../../../../../../shared/source/presentation/component/source-cite";
import {isMobile} from 'react-device-detect';

export interface IngredientSeasonCardProps {
    index: number
    maxIndex: number
    item: IngredientSeasonViewModel
}

export default function IngredientSeasonCard({index, maxIndex, item}: IngredientSeasonCardProps) {

    const [active, setActive] = useState(false)
    const [height, setHeight] = useState('0px')
    const [rotate, setRotate] = useState('transform duration-700 ease')
    const contentSpace = useRef(null)

    const {t, language} = useI18next();

    const dateStyle = "text-sm sm:text-sm text-white  md:-rotate-0 align-middle text-center py-1";
    const dotStyle = "text-sm bg-gray-100 py-1 border-b"
    const color_even = " bg-[#9B0039]";
    const color_uneven = " bg-[#d70b58]"

    function toggleAccordion() {
        setActive(!active)
        if (contentSpace.current ) {
            // @ts-ignore
            setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`)
        }
        setRotate(active ? 'transform duration-400 ease' : 'transform duration-400 ease rotate-180')
    }

    const Heading = (): JSX.Element => {
        const common_name = item.props.entity?.localized_common_name_preferred(language)
        const common_name_japanese = item.props.entity?.japanese_common_name_preferred();
        const capitalized_common_name = capitalizeFirstLetter(common_name);
        const origin_main_areas = item.fishing_area_main_names

        return (
            <>
                <div className={"flex-row sm:flex justify-between items-center"}>
                    <div className="text-sm sm:text-base font-bold">
                        <span className="">{capitalized_common_name}</span> <span>【{common_name_japanese}】</span><br/>
                    </div>
                    <div className="italic text-sm">
                        {origin_main_areas.length > 0 &&
                            <span>{t("Ingredients.BestSeasonCalendar.origin")}: {origin_main_areas.join(", ")}</span>}
                    </div>
                </div>

            </>
        )
    }

    const SourceInformation = (): JSX.Element => {
        return (
            <>
                {item.props.source &&
                    <div className="text-xs mt-2">
                        <span>{t("Ingredients.BestSeasonCalendar.source")}</span>:
                        <SourceCite viewModel={item.props.source} addShort={false}/>
                    </div>
                }
            </>
        )
    }

    return (
        <>
            <div className="flex-col">

                <div className={"bg-gray-100 p-2" + (index === 0 ? " rounded-t-lg" : "")}>
                    <div className="flex justify-between items-center">
                        <div>
                            <Heading/>
                        </div>
                        {/* Open Info */}
                        <div>
                            {/*<InformationCircleIcon className="h-3"/>*/}
                            {item.props.source && <button
                                className=" box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-between"
                                onClick={toggleAccordion}
                            >
                                <LibraryIcon className="h-4"/>
                            </button>}
                        </div>
                    </div>

                    {/* Source information */}
                    {item.props.source && <div
                        ref={contentSpace}
                        style={{maxHeight: `${height}`}}
                        className="overflow-auto transition-max-height duration-700 ease-in-out"
                    >
                        <SourceInformation/>
                    </div>}

                </div>


                <div className="flex justify-between divide-x">
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_even}>{isMobile ? "J" : "Jan"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.january}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_uneven}>{isMobile ? "F" : "Feb"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.february}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_even}>{isMobile ? "M" : "Mar"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.march}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_uneven}>{isMobile ? "A" : "Apr"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.april}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_even}>{isMobile ? "M" : "May"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.may}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_uneven}>{isMobile ? "J" : "Jun"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.june}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_even}>{isMobile ? "J" : "Jul"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.july}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_uneven}>{isMobile ? "A" : "Aug"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.august}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_even}>{isMobile ? "S" : "Sep"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.september}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_uneven}>{isMobile ? "O" : "Oct"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.october}/></div>
                    </div>
                    <div className={"flex-row grow"}>
                        <div className={dateStyle + color_even}>{isMobile ? "N" : "Nov"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.november}/></div>
                    </div>
                    <div className={"flex-row grow "}>
                        <div className={dateStyle + color_uneven}>{isMobile ? "D" : "Dec"}</div>
                        <div className={dotStyle}><SeasonValue value={item.props.season.december}/></div>
                    </div>

                </div>
                {item.props.remarks && <div className={"bg-gray-100 p-2" + (index === maxIndex ? " rounded-b-lg" : "")}>
                    <span className="text-sm">{item.props.remarks}</span>
                </div>}

            </div>
        </>
    )
}


interface SeasonValueProps {
    value: number
}

function SeasonValue({value}: SeasonValueProps): JSX.Element {
    if (value === 50) {
        return (
            <div className="align-middle text-center">○</div>
        )
    }
    if (value === 100) {
        return (
            <div className="align-middle text-center">●</div>
        )
    }
    return (<div className="align-middle text-center invisible">●</div>)
}
