import {IngredientTranslation} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientAuthorViewType, IngredientDateViewType, IngredientMetaViewType, IngredientTranslationContentViewType,
    IngredientTranslationViewModel,
    IngredientTranslationViewModelProps
} from "../model/ingredient-translation-view-model";

export class IngredientTranslationFactory {
    private readonly response: IngredientTranslation;

    constructor(response:  IngredientTranslation) {
        this.response = response;
    }

    public execute(): IngredientTranslationViewModel {
        const props: IngredientTranslationViewModelProps = {
            authors: this.map_authors(),
            content: this.map_content(),
            dates: this.map_dates(),
            locale: this.map_locale(),
            meta: this.map_meta()

        }
        return IngredientTranslationViewModel.create(props);
    }

    private map_authors(): IngredientAuthorViewType[] {
        const authorResponse = this.response.authors;
        if (authorResponse) {
            if (authorResponse.length > 0) {
                return this.response.authors.map((author) => {
                    return {
                        email: author.directus_users_id.email,
                        firstName: author.directus_users_id.first_name,
                        lastName: author.directus_users_id.last_name,
                        title: author.directus_users_id.title
                    }
                })
            } else {
                return []
            }
        } else {
            return []
        }
    }

    private map_content(): IngredientTranslationContentViewType {
        return {
            as_ingredient_for_sushi_sashimi: this.response.content_ingredient_sushi_sashimi,
            characteristics_and_ecology: this.response.content_characteristics_ecology,
            definition: this.response.content_definition,
            economy: this.response.content_economy,
            in_japan: this.response.content_japan,
            trivia: this.response.content_trivia,
            types: this.response.content_types
        };
    }

    private map_dates(): IngredientDateViewType {
        return {
            created: this.response.date_created,
            updated: this.response.date_updated
        };
    }

    private map_locale(): string {
        return this.response.languages_code.locale;
    }

    private map_meta(): IngredientMetaViewType {
        return {
            description: this.response.meta_description
        }
    }
}