import {ImagesDistributionMetaEdge} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientImageDistributionMetaType,
    IngredientImageDistributionProps,
    IngredientImageDistributionViewModel
} from "../model/ingredient-image-distribution";
import {GraphImageNode} from "../../../../../shared/infrastructure/graph-image-node";

export class IngredientImageDistributionFactory {
    private readonly image: GraphImageNode;
    private readonly meta: ImagesDistributionMetaEdge;

    constructor(imageNode: GraphImageNode, metaInformationNode: ImagesDistributionMetaEdge) {
        this.image = imageNode;
        this.meta = metaInformationNode;
    }

    public build(): IngredientImageDistributionViewModel {
        const props: IngredientImageDistributionProps = {
            imageEdge: this.map_image(),
            meta: this.map_meta()
        }
        return IngredientImageDistributionViewModel.create(props);
    }

    private map_image(): any {
        return this.image;
    }

    private map_meta(): IngredientImageDistributionMetaType {
        const meta = this.meta;
        if (meta) {
            return {
                common_names: {
                    de: meta.node.frontmatter.common_name_de,
                    en: meta.node.frontmatter.common_name_en,
                    es: meta.node.frontmatter.common_name_es,
                    ja: meta.node.frontmatter.common_name_ja
                },
                species: meta.node.frontmatter.genus_species,
                source: meta.node.frontmatter.literature_source
            }
        } else {
            return {
                common_names: {
                    de: null,
                    en: null,
                    es: null,
                    ja: null
                },
                species: null,
                source: null
            }
        }
    }

}