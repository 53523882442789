import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {IngredientGalleryImageViewModel} from "../../model/ingredient-gallery-image-view-model";

export interface IngredientGalleryImageCiteBuilderProps {
	image: IngredientGalleryImageViewModel
}

export function IngredientGalleryImageCiteBuilder({image}: IngredientGalleryImageCiteBuilderProps) {

	const {t} = useI18next();
	const linkColor = "text-salmonRed";
	const aHrefRel = "external nofollow noopener noreferrer";
	const referrerPolicy = "no-referrer";

	return (
		<span key={`span-${image.props.context.photographer.name}-${image.props.context.original.title}`}>
			<PhotographerName/> <ImageTitle/> <ImageSource/> <ImageLicense/> <ImageChanges/>
		</span>
	)

	function PhotographerName(): JSX.Element {
		const photographerName = image.props.context.photographer.name;
		const photographerUrl = image.props.context.photographer.url;

		if (photographerName) {
			if (photographerUrl) {
				return (
					<>
						<a className={linkColor}
						   rel={aHrefRel}
						   referrerPolicy={referrerPolicy}
						   key={`a-name-${photographerName}-${photographerName}`}
						   href={photographerUrl}
						   title={photographerName}
						>
							{photographerName}
						</a>.
					</>
				)
			} else {
				return (
					<>
						{photographerName}.
					</>
				)
			}
		} else {
			return (<></>)
		}
	}

	function ImageTitle(): JSX.Element {
		const imageTitle = image.props.context.original.title;
		const imageSourceUrl = image.props.context.original.source.url;

		if (imageTitle) {
			if (imageSourceUrl) {
				return (
					<>
						<a className={linkColor}
						   rel={aHrefRel}
						   referrerPolicy={referrerPolicy}
						   key={`a-imgTitle-${imageTitle}-${imageTitle}`}
						   href={imageSourceUrl}
						   title={imageTitle}
						>
							{imageTitle}
						</a>.
					</>
				)
			} else {
				return (
					<>
						{imageTitle}.
					</>
				)
			}
		} else {
			return (<></>)
		}
	}

	function ImageSource(): JSX.Element {
		const imageSourceName = image.props.context.original.source.provider;

		if (imageSourceName) {
			return (
				<>
					{imageSourceName}.
				</>
			)
		} else {
			return (
				<></>
			)
		}
	}

	function ImageLicense(): JSX.Element {
		const photographerName = image.props.context.photographer.name;
		const photographerUrl = image.props.context.photographer.url;
		const licenseName = image.props.context.license.name;
		const licenseUrl = image.props.context.license.url;

		if (licenseName) {
			if (licenseUrl) {
				return (
					<>
						{`${t("Ingredients.Gallery.Sources.license")}: `}
						<a className={linkColor}
						   rel={aHrefRel}
						   referrerPolicy={referrerPolicy}
						   key={`a-licenseUrl-${photographerName}-${photographerUrl}-${licenseName}`}
						   href={licenseUrl}
						   title={licenseName}
						>
							{licenseName}
						</a>.
					</>
				)
			} else {
				return (
					<>
						{t("Ingredients.Gallery.Sources.license")}: {licenseName}.
					</>
				)
			}
		} else {
			return (<></>)
		}
	}

	function ImageChanges(): JSX.Element {
		const changes = image.props.context.original.changes;
		const changes_translated = changes.map((change) => mapImageChangesKeywords(change))

		if (changes) {
			if (changes.length > 0){
				return (
					<>
						{t("Ingredients.Gallery.Sources.Changes.prefix")}: {changes_translated.join(", ")}.
					</>
				)
			} else {
				return (
					<></>
				)
			}
		} else {
			return (<></>)
		}

	}

	function mapImageChangesKeywords(input: string): string {
		return t(`Ingredients.Gallery.Sources.Changes.${input}`)
	}
}
