import {Warning} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientWarningTranslationViewType,
    IngredientWarningViewModel,
    IngredientWarningViewModelProps
} from "../model/ingredient-warning-view-model";
import {SourceViewModelFactory} from "../../../../../shared/source/application/mapper/from-graphql-to-view-model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";

export class IngredientWarningsFactory {
    private readonly response: Warning;

    constructor(response: Warning) {
        this.response = response;
    }

    public execute(): IngredientWarningViewModel {
        const props: IngredientWarningViewModelProps = {
            source: this.map_source(),
            translations: this.map_translations(),
            umbrella_slug: this.map_slug()
        }
        return IngredientWarningViewModel.create(props);
    }

    private map_source(): SourceViewModelNew {
        return new SourceViewModelFactory(this.response.source).execute();
    }

    private map_translations(): IngredientWarningTranslationViewType[] {
        return this.response.warnings_id.threat.translations.map((translation) => {
            return {
                body: translation.body,
                language: translation.languages_code.locale,
                title: translation.title
            }
        })
    }

    private map_slug(): string {
        return this.response.warnings_id.threat.umbrella_slug;
    }
}