import React from "react";


const IngredientContentBodyHeadingH3 = (props: {
	id: string | null,
	children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
	return (
		<>
		<h3 id={props.id ?? ``} className="text-base lg:text-xl font-bold mb-1 mt-5">
			{props.children}
		</h3>
			<hr className="mb-3"/>
		</>
	)
}

export default IngredientContentBodyHeadingH3;
