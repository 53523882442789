import React from "react";
import {useI18next} from "gatsby-plugin-react-i18next";

export interface IngredientContentBodyQuoteProps {
	quote: string
	author: string
	title: string
	cite: string
	isTranslation: boolean
}

export default function IngredientContentBodyQuote({quote, author, title, cite, isTranslation}: IngredientContentBodyQuoteProps) {
	const {t} = useI18next();

	return (
		<blockquote>
			<div className="">
				<div className={"flex mb-12 mt-10"}>

					<div><span className="text-6xl pr-2 font-serif">“</span></div>
					<div>
						<div className="mt-4">
							<p>
								<span className="italic font-serif text-lg">{quote.trimEnd().trimStart()}</span>
							</p>
						</div>
						<div className="mt-2">
							<p className="text-sm font-bold">- <span>{author}</span>{title && ", "}
								<span className="font-light">{title}</span>
								{cite && <span><a className="font-light" href={`#${cite}`}> [{cite}] {isTranslation ? `(${t("Ingredients.Content.Quote.is_translation")})` : ""}</a></span>}</p>
						</div>
					</div>

				</div>

			</div>

		</blockquote>
	)
}
