import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";
import {ExclamationIcon} from "@heroicons/react/outline";

export interface IngredientWarningsProps {
    viewModel: IngredientAggregateViewModel
}

export function IngredientWarnings({viewModel}: IngredientWarningsProps) {
    const {t, language} = useI18next()
    if (viewModel.props.warnings.length > 0) {
        return (
            <>
                <IngredientContentBodyHeadingH2 id={"warnings"}>
                    {t("Ingredients.Warnings.title", {name: viewModel.props.title})}
                </IngredientContentBodyHeadingH2>
                <div>
                    <ol className="mb-3">
                        {viewModel.props.warnings.map((warning, index) => {
                            const source = warning.props.source;
                            const translation = warning.props.translations.filter((translation) => translation.language === language);
                            return translation.map((trans) => {
                                return (
                                    <li className="my-2" key={index}>
                                        <div className="w-fill flex p-3 pl-3 bg-gray-100 rounded-lg">
                                            <ExclamationIcon className="flex-none w-6 h-full"/>
                                            <span className="ml-2" key={index}>
                                                <span className="font-bold">{translation[0].title.toLocaleUpperCase()}</span>: {trans.body} <a href={`#${source.props.short}`}>[{source.props.short}]</a>
                                            </span>
                                        </div>
                                    </li>)
                            })
                        })}
                    </ol>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
}