import {LinkIcon, UploadIcon} from "@heroicons/react/outline";
import parse, {domToReact, Element, HTMLReactParserOptions} from "html-react-parser";
import * as React from "react";
import ArticleContentImage from "../../../../../../../article/submodule/article/presentation/component/content/article-content-image";
import {
	clean_filename,
	filterImageDataByFilename
} from "../../../../../../../article/submodule/article/presentation/component/content/article-content-parser";
import IngredientContentBodyHeadingH3 from "../ingredient-content-body-heading-h3";
import IngredientBodyParagraph from "../ingredient-content-body-paragraph";
import IngredientContentBodyQuote from "../ingredient-content-body-quote";
import IngredientContentBodyHeadingH4 from "../ingredient-content-body-heading-h4";

export interface IngredientContentBodyParser {
	htmlInput: string,
	contentImageNodes: any[]
}

export default function IngredientContentBodyParser({htmlInput, contentImageNodes}: IngredientContentBodyParser) {

	const options: HTMLReactParserOptions = {
		trim: true,
		// @ts-ignore
		replace: (domNode) => {

			if (!domNode) {
				return;
			}
			const dom: any = domNode

			if ( dom.attribs && dom.name === 'img' && dom.attribs.class === 'content-image') {
				return replaceImageToGatsbyImage(domNode, contentImageNodes)
			}

			if (dom.attribs && dom.name === "a" && dom.attribs.class === 'ref') {
				if (domNode instanceof Element) {
					const src = domNode.attribs['short']
					return (
						<span className=""><a className="reference" href={`#${src}`} title={src}>[{src}]</a></span>
					);
				}
			}

			const classesScript = ["kanji", "kana", "romaji", "kanaKanji", "japTransl"]
			if (dom.attribs && dom.name === "span" && (classesScript.includes(dom.attribs.class))) {
				if (domNode instanceof Element) {
					return (
						<span className={"text-[#676767]"}>
							{domToReact(domNode.children, options)}
						</span>
					);
				}
			}

			if ((dom.attribs && dom.name === "a") && (dom.attribs.class !== 'ref') && (dom.attribs.class !== 'inbound')) {
				if (domNode instanceof Element) {
					const href = domNode.attribs['href']
					const title = domNode.attribs['title']
					const rel = "external nofollow noreferrer noopener";
					const policy = "no-referrer"
					return (
						<a className="outbound text-salmonRed"
						   href={href}
						   title={title}
						   rel={rel}
						   referrerPolicy={policy}
						>
							<span className={"underline underline-offset-1"}>{domToReact(domNode.children, options)}</span>
						</a>
					);
				}
			}

			if ((dom.attribs && dom.name === "a") && (dom.attribs.class === 'inbound')) {
				if (domNode instanceof Element) {
					const id = domNode.attribs['id']
					const href = domNode.attribs['href']
					const title = domNode.attribs['title']
					return (
						<a className="inbound text-salmonRed"
						   id={id}
						   href={href}
						   title={title}
						>
							<span className={"underline underline-offset-1"}>{domToReact(domNode.children, options)}</span>
						</a>
					);
				}
			}

			if (dom.attribs && dom.name === "p") {
				if (domNode instanceof Element) {
					return (
						<IngredientBodyParagraph>
							{domToReact(domNode.children, options)}
						</IngredientBodyParagraph>
					);
				}
			}

			if (dom.attribs && dom.name === "h3") {
				if (domNode instanceof Element) {
					const id = domNode.attribs['id']
					return (
						<IngredientContentBodyHeadingH3 id={id}>
							{domToReact(domNode.children, options)}
						</IngredientContentBodyHeadingH3>
					);
				}
			}

			if (dom.attribs && dom.name === "h4") {
				if (domNode instanceof Element) {
					const id = domNode.attribs['id']
					return (
						<IngredientContentBodyHeadingH4 id={id}>
							{domToReact(domNode.children, options)}
						</IngredientContentBodyHeadingH4>
					);
				}
			}

			if (dom.attribs && dom.name === "ul") {
				if (domNode instanceof Element) {

					return (
						<ul className="list-disc pl-5 my-4 font-base">
							{domToReact(domNode.children, options)}
						</ul>
					);
				}
			}

			if (dom.attribs && dom.name === "ol") {
				if (domNode instanceof Element) {

					return (
						<ol className="list-decimal pl-5 my-4 font-base">
							{domToReact(domNode.children, options)}
						</ol>
					);
				}
			}

			if (dom.attribs && dom.name === "div" && dom.attribs.class === 'quote') {
				if (domNode instanceof Element) {
					// @ts-ignore
					const text = domNode.children[0].data;
					const author = domNode.attribs['author'];
					const title = domNode.attribs['title'];
					const cite = domNode.attribs['cite'];
					const isTranslation = (domNode.attribs['istranslation'] === "true");

					if (domNode instanceof Element && domNode.attribs) {
						return (
							<IngredientContentBodyQuote quote={text} author={author} title={title} cite={cite} isTranslation={isTranslation}/>
						)
					}
				}

			}

			// if (dom.attribs && dom.name === "table") {
			// 	if (domNode instanceof Element) {
			// 		if (domNode instanceof Element && domNode.attribs) {
			// 			return (
			// 				<table className="mt-3 mb-7 table-auto">
			// 					{domToReact(domNode.children, options)}
			// 				</table>
			// 			)
			// 		}
			// 	}
			// }
			//
			// if (dom.attribs && dom.name === "thead") {
			// 	if (domNode instanceof Element) {
			// 		if (domNode instanceof Element && domNode.attribs) {
			// 			return (
			// 				<thead className="border-b border-t border-collapse">
			// 					{domToReact(domNode.children, options)}
			// 				</thead>
			// 			)
			// 		}
			// 	}
			// }
			//
			// if (dom.attribs && dom.name === "th") {
			// 	if (domNode instanceof Element) {
			// 		if (domNode instanceof Element && domNode.attribs) {
			// 			return (
			// 				<th className={`text-white text-left bg-[#9B0039]`} >
			// 					{domToReact(domNode.children, options)}
			// 				</th>
			// 			)
			// 		}
			// 	}
			// }
			//
			// if (dom.attribs && dom.name === "td") {
			// 	if (domNode instanceof Element) {
			// 		if (domNode instanceof Element && domNode.attribs) {
			// 			return (
			// 				<td className={`break-words`} >
			// 					{domToReact(domNode.children, options)}
			// 				</td>
			// 			)
			// 		}
			// 	}
			// }

		}
	}

	const parsed = parse(htmlInput, options)

	// Return as Element
	return parsed as JSX.Element

}

function replaceImageToGatsbyImage(domNode: any, contentImageData: any[]) {

	const {filename, alt, title, caption, credit} = domNode.attribs

	// Filter the image by the extracted filename
	const filename_clean = clean_filename(filename);
	const image = filterImageDataByFilename(contentImageData, filename_clean);

	if (image) {
		if (image.node) {
			return (
				<ArticleContentImage
					gatsbyImageData={image.node}
					title={title}
					alt={alt}
					publicUrl={image.node.publicURL}
					caption={caption}
					credit={credit}
				/>
			)

		}
	}
}
