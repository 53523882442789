import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";

export type IngredientWarningTranslationViewType = {
	language: string,
	body: string
	title: string
}

export interface IngredientWarningViewModelProps {
	umbrella_slug: string,
	translations: Array<IngredientWarningTranslationViewType>,
	source: SourceViewModelNew
}

export class IngredientWarningViewModel extends ViewModel<IngredientWarningViewModelProps> {

	private constructor(props: IngredientWarningViewModelProps) {
		super(props);
	}

	public static create(props: IngredientWarningViewModelProps): IngredientWarningViewModel {
		return new IngredientWarningViewModel({...props})
	}

}

