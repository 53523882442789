import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {IngredientEntityFamilyCommonNameViewModel} from "./ingredient-entity-family-common-name-view-model";

export interface IngredientEntityFamilyViewModelProps {
	title: string
	commonNames: IngredientEntityFamilyCommonNameViewModel[]
}

export class IngredientEntityFamilyViewModel extends ViewModel<IngredientEntityFamilyViewModelProps> {

	private constructor(props: IngredientEntityFamilyViewModelProps) {
		super(props);
	}

	public static create(props: IngredientEntityFamilyViewModelProps): IngredientEntityFamilyViewModel {
		return new IngredientEntityFamilyViewModel({...props})
	}

}
