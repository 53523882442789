import {IngredientAggregateResponse} from "../../infrastructure/dto/ingrdient-graphql-response";
import {IngredientImagesViewType} from "../model/ingredient-aggregate-view-model";
import {IngredientGalleryImageViewModel} from "../model/ingredient-gallery-image-view-model";
import {GraphImagesEdge} from "../../../../../shared/infrastructure/graph-image-node";
import {IngredientImageDistributionViewModel} from "../model/ingredient-image-distribution";
import {IngredientImageDistributionFactory} from "./ingredient-image-distribution-factory";

export class IngredientImagesFactory {
    private readonly response: IngredientAggregateResponse;

    constructor(response: IngredientAggregateResponse) {
        this.response = response;
    }

    public execute(): IngredientImagesViewType {
        return {
            opener: this.response.images_opener.edges,
            content: this.response.images_content.edges,
            distribution_maps: this.map_distribution_images(),
            infobox: {
                illustration: this.response.images_infobox_illustration.edges
            },
            gallery: {
                images: this.map_gallery_images()
            },
        }
    }

    /**
     * Merges the meta data from the markdown files with the gatsby images edges
     * @private
     */
    private map_gallery_images(): IngredientGalleryImageViewModel[] {
        const zippedImages = this.merge_thumbnail_with_full_images();
        return zippedImages.map((zip) => {
            const edge = zip[0] // big image
            const edgeSmall = zip[1] // small image
            const imageEdgeFilename = edge.node.name;
            const related_metadata = this.response.images_gallery_meta.edges
                .filter((edge) => edge.node.fileAbsolutePath
                    .includes(imageEdgeFilename))[0]
            return IngredientGalleryImageViewModel.create_from_graphql_edge(edge, edgeSmall, related_metadata);
        })
    }

    /**
     * Merges the thumbnails with the full resolution images
     * @private
     */
    private merge_thumbnail_with_full_images(): GraphImagesEdge[][] {
        return this.response.images_gallery.edges.map((edge, i) => {
            const smallEdge = this.response.images_gallery_small.edges[i]
            return [edge, smallEdge]
        })
    }

    private map_distribution_images(): IngredientImageDistributionViewModel[] {
        const distribution_images = this.response.images_distribution_map.edges;
        const distribution_metas = this.response.images_distribution_meta.edges;

        return distribution_images.map((map) => {
            const image = map.node;
            const meta = distribution_metas.filter((meta) => meta.node.fileAbsolutePath.includes(image.name))[0]
            return new IngredientImageDistributionFactory(image, meta).build()
        }).sort((a, b) => (a.props.meta.species < b.props.meta.species) ? -1:1)
    }
}