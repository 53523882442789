import {Ingredient, IngredientAggregateResponse} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientAggregateViewModel,
    IngredientAggregateViewModelProps, IngredientImagesViewType, IngredientAvailableLanguages
} from "../model/ingredient-aggregate-view-model";
import {IngredientCategory} from "../model/ingredient-category";
import {IngredientCategoryFactory} from "./ingredient-category-factory";
import {IngredientEntityViewModel} from "../model/ingredient-entity-view-model";
import {IngredientTranslationViewModel} from "../model/ingredient-translation-view-model";
import {IngredientWarningViewModel} from "../model/ingredient-warning-view-model";
import {IngredientEntityFactory} from "./ingredient-entity-factory";
import {IngredientImagesFactory} from "./ingredient-images-factory";
import {SourceViewModelFactory} from "../../../../../shared/source/application/mapper/from-graphql-to-view-model";
import {IngredientTranslationFactory} from "./ingredient-translation-factory";
import {IngredientWarningsFactory} from "./ingredient-warnings-factory";
import {IngredientAvailableLanguagesFactory} from "./ingredient-available-languages-factory";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";
import {IngredientSeasonViewModel} from "../model/ingredient-season-view-model";
import {IngredientSeasonsFactory} from "./ingredient-seasons-factory";

export class IngredientAggregateFactory {
    private readonly response: IngredientAggregateResponse;
    private ingredient: Ingredient;

    /**
     * The response from the backend will be mapped to the viewModel.
     * @param localizedResponse: Translation must be filtered by graphQL query
     */
    constructor(localizedResponse: IngredientAggregateResponse) {
        this.response = localizedResponse;
        this.ingredient = localizedResponse.directus.ingredients[0];
    }

    execute(): IngredientAggregateViewModel {
        const props: IngredientAggregateViewModelProps = {
            available_languages: this.map_available_languages(),
            category: this.map_category(),
            collectiveTerm: this.map_collective_term(),
            entity_authentic: this.map_entities_authentic(),
            entity_substitute: this.map_entities_substitute(),
            entity_main: this.map_entity_main(),
            images: this.map_images(),
            slug: this.map_slug(),
            sources: this.map_sources(),
            title: this.map_title(),
            translations: this.map_translations(),
            warnings: this.map_warnings(),
            seasons: this.map_seasons(),
        }
        return IngredientAggregateViewModel.create(props)
    }

    private map_available_languages(): IngredientAvailableLanguages[] {
        return this.response.available_languages.ingredients.map((available) => {
            return new IngredientAvailableLanguagesFactory(available).execute();
        }).flat()
    }

    private map_category(): IngredientCategory {
        const category = this.ingredient.category;
        return IngredientCategoryFactory.create_from_string(category)
    }

    private map_collective_term(): string | null {
        return this.ingredient.collective_term;
    }

    private map_entities_authentic(): IngredientEntityViewModel[] {
        return this.ingredient.entities_authentic.map((entity) => {
            return new IngredientEntityFactory(entity.entities_id).execute()
        })
    }

    private map_entities_substitute(): IngredientEntityViewModel[] {
        return this.ingredient.entities_substitute.map((entity) => {
            return new IngredientEntityFactory(entity.entities_id).execute()
        })
    }

    private map_entity_main(): IngredientEntityViewModel {
        const main_entity = this.ingredient.entity_main;
        return new IngredientEntityFactory(main_entity).execute()
    }

    private map_images(): IngredientImagesViewType {
        return new IngredientImagesFactory(this.response).execute();
    }

    private map_slug(): string {
        return this.ingredient.umbrella_slug;
    }

    private map_sources(): SourceViewModelNew[] {
        return this.ingredient.sources.map((source) => {
            return new SourceViewModelFactory(source.sources_id).execute();
        })
    }

    private map_title(): string {
        return this.ingredient.title;
    }

    private map_translations(): IngredientTranslationViewModel[] {
        // filtered by language in graph query
        const translation = this.ingredient.translations[0];
        const viewModel = new IngredientTranslationFactory(translation).execute();
        return [viewModel];
    }

    private map_warnings(): IngredientWarningViewModel[] {
        return this.ingredient.warnings.map((warning) => {
            return new IngredientWarningsFactory(warning).execute();
        })
    }

    private map_seasons(): IngredientSeasonViewModel[] {
        const seasons = this.ingredient.seasons;
        if (seasons) {
            if (seasons.length > 0) {
                return seasons.map((season) => {
                    return new IngredientSeasonsFactory(season).execute();
                })
            } else {
                return []
            }
        } else {
            return []
        }

    }

}