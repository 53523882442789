import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import React from "react";
import {useI18next} from "gatsby-plugin-react-i18next";
import {toKatakana} from "wanakana";
import {titleCase} from "../../../../../../shared/helper/title_case";

export interface IngredientHeadingProps {
    viewModel: IngredientAggregateViewModel
}

export function IngredientHeading({viewModel}: IngredientHeadingProps) {

    const {language} = useI18next();
    const {title} = viewModel.props;

    const kanjiPreferred_from_main = viewModel.japanese_main_entity_kanji_preferred();

    // If the heading is too long, set fo flex row mode
    const headingFlexStyle = (): string => {
        if (title.length > 10) {
            return ""
        } else {
            return "flex flex-col sm:flex-row justify-between"
        }
    }

    const romajiStyle = (): string => {
        const base = "font-headline text-4xl sm:text-3xl md:text-5xl lg:text-5xl text-salmonRed font-bold break-words";
        if (title.length > 10) {
            return base + " mb-1"
        } else {
            return base
        }
    }

    const commonNamePreferred_from_main = (): string | null | undefined => {
        if (viewModel.props.entity_main.props.commonNames.length > 0) {
            const preferred = viewModel.props.entity_main.props.commonNames
                .filter((name) => ((name.props.locale === language) && (name.props.script === 'latin')))
                .filter((entity) => entity.props.isPreferred)
            if (preferred.length > 0) {
                return preferred[0].props.commonName;
            }
        } else {
            return null
        }
    }

    return (
        <div id="definition" >
            <div className={headingFlexStyle()}>
                <div>
                    <h1 className={romajiStyle()}>
                        {title} Sushi
                    </h1>
                </div>
                <div className="text-lg sm:text-2xl text-black sm:self-center">
                    <span >
                    {toKatakana(title
                        .replaceAll(" ", "")
                        , {
                        customKanaMapping: {
                            m: 'ン',
                        }
                    })}すし{kanjiPreferred_from_main ? `・${kanjiPreferred_from_main}寿司` : ""}
                </span>
                </div>
            </div>
            <h2 className="text-xl md:text-2xl text-black font-bold">{titleCase(commonNamePreferred_from_main())}</h2>
        </div>
    )

    // return (
    //     <>
    //         <h1 className="text-2xl mt-3 sm:text-3xl md:text-5xl lg:text-5xl text-salmonRed font-bold break-words">{title} Sushi</h1>
    //         <h2 className="text-xl md:text-2xl text-black font-bold">{capitalizeFirstLetter(commonNamePreferred_from_main())} <span>【{toKatakana(viewModel.props.title)}】</span></h2>
    //     </>
    // )
}

function capitalizeFirstLetter(input: string | null | undefined): string | null {
    if (input) {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }
    return null;
}
