import {graphql} from "gatsby";
import React from "react";
import Layout from "../components/layout";
import IngredientPageContent from "../modules/ingredients/submodules/article/presentation/page/ingredient-page-content";
import IngredientSeo from "../modules/ingredients/submodules/article/presentation/components/seo/ingredient-seo";
import {useAppStore} from "../state/app_state";
import {
	IngredientGraphResponseToViewModelUseCase
} from "../modules/ingredients/submodules/article/application/usecase/ingredient-graph-response-to-view-model-use-case";

/*

{
  "language": "en",
  "umbrella_slug": "iwashi",
  "regex_images_opener": "/ingredients/iwashi/opener/",
  "regex_images_content": "/ingredients/iwashi/content/",
  "regex_images_gallery": "/ingredients/iwashi/gallery/",
  "regex_images_distribution_map": "/ingredients/iwashi/map/",
  "regex_images_infobox_illustration": "/ingredients/iwashi/infobox/illustration/"
}

 */

export const query = graphql`
fragment LanguageData on DIRECTUS_languages {
  code
  locale
  name
}

fragment SourceData on DIRECTUS_sources {
  id
  author
  author_translated_roman
  doi
  journal_issue
  journal_name
  journal_volume
  pages
  publication_date
  publication_year
  published_language {
    ...LanguageData
  }
  publisher
  short
  sort
  status
  title
  title_translated_english
  type
  url
  date_retrieved
}

fragment EntityData on DIRECTUS_entities {
  id
  sort
  status
  title
  species
  genus
  translations {
    id
    status
    sort
    script
    name
    is_preferred
    languages_code {
      ...LanguageData
    }
  }
  family {
    id
    sort
    status
    title
    translations {
      id
      languages_code {
        ...LanguageData
      }
      name
      script
    }
  }
}

fragment ImageData on FileEdge {
  node {
    id
    sourceInstanceName
    name
    relativePath
    relativeDirectory
    publicURL
    ext
    extension
    dir
    root
    base
    absolutePath
    childImageSharp {
      id
      gatsbyImageData(
        width: 1200
        quality: 75
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
}

fragment ImageSmallData on FileEdge {
  node {
    id
    sourceInstanceName
    name
    relativePath
    relativeDirectory
    publicURL
    ext
    extension
    dir
    root
    base
    absolutePath
    childImageSharp {
      id
      gatsbyImageData(width: 600, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
    }
  }
}

fragment ImageMeta on MarkdownRemarkEdge {
  node {
    id
    fileAbsolutePath
    frontmatter {
      photographer_name
      photographer_url
      photo_original_title
      photo_original_source_url
      photo_original_source_provider
      photo_license
      photo_license_url
      photo_change_tags
      photo_alt_en
      photo_alt_de
      photo_alt_es
    }
  }
}

fragment DistributionImageMeta on MarkdownRemarkEdge {
  node {
    id
    fileAbsolutePath
    frontmatter {
      genus_species
      common_name_en
      common_name_de
      common_name_es
      common_name_ja
      literature_source
    }
  }
}

query ($umbrella_slug: String!, $language: String!, $regex_images_opener: String!, $regex_images_content: String!, $regex_images_distribution_map: String!, $regex_images_infobox_illustration: String!, $regex_images_gallery: String!) {
  directus {
    ingredients(filter: {umbrella_slug: {_eq: $umbrella_slug}}) {
      id
      status
      category
      collective_term
      title
      umbrella_slug
      entity_main {
        ...EntityData
      }
      entities_substitute {
        id
        entities_id {
          ...EntityData
        }
      }
      entities_authentic {
        id
        entities_id {
          ...EntityData
        }
      }
      sources {
        sources_id(sort: "short") {
          ...SourceData
        }
      }
      translations(filter: {languages_code: {locale: {_eq: $language}}}) {
        authors {
          id
          directus_users_id {
            email
            first_name
            last_name
            title
          }
        }
        content_characteristics_ecology
        content_definition
        content_economy
        content_ingredient_sushi_sashimi
        content_types
        content_japan
        content_trivia
        date_created
        date_updated
        id
        intro
        languages_code {
          ...LanguageData
        }
        meta_description
        meta_title
        status
      }
      warnings {
        id
        warnings_id {
          threat {
            status
            umbrella_slug
            translations(filter: {languages_code: {locale: {_eq: $language}}}) {
              languages_code {
                ...LanguageData
              }
              title
              body
            }
          }
        }
        source {
          ...SourceData
        }
      }
      seasons {
        id
        ingredients_id {
          id
          title
        }
        ingredient_season_id {
          january
          february
          march
          april
          may
          june
          july
          august
          october
          september
          november
          december
          fishing_areas {
            fishing_areas_id {
              id
              fao_number
              translations(filter: {languages_code: {locale: {_eq: $language}}}) {
                area_main
                area_sub
                languages_code {
                  ...LanguageData
                }
              }
            }
          }
          entity {
            ...EntityData
          }
          translations(filter: {languages_code: {locale: {_eq: $language}}}) {
            id
            origin
            remarks
            languages_code {
              ...LanguageData
            }
          }
          source {
            ...SourceData
          }
        }
      }
    }
  }
  available_languages: directus {
    ingredients(filter: {umbrella_slug: {_eq: $umbrella_slug}}) {
      id
      umbrella_slug
      translations {
        languages_code {
          ...LanguageData
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  images_opener: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_opener}}
  ) {
    edges {
      ...ImageData
    }
  }
  images_content: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_content}}
  ) {
    edges {
      ...ImageData
    }
  }
  images_distribution_map: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_distribution_map}}
  ) {
    edges {
      ...ImageData
    }
  }
  images_distribution_meta: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: $regex_images_distribution_map}}
  ) {
    edges {
      ...DistributionImageMeta
    }
  }
  images_infobox_illustration: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_infobox_illustration}}
  ) {
    edges {
      ...ImageSmallData
    }
  }
  images_gallery: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_gallery}}
  ) {
    edges {
      ...ImageData
    }
  }
  images_gallery_small: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_gallery}}
  ) {
    edges {
      ...ImageSmallData
    }
  }
  images_gallery_meta: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: $regex_images_gallery}}
  ) {
    edges {
      ...ImageMeta
    }
  }
}
`

export default function IngredientTemplatePage({data}: any) {

	// State
	const setIngredientSection = useAppStore(state => state.setIngredients);
	setIngredientSection()

	const viewModel = IngredientGraphResponseToViewModelUseCase.execute(data);

	return (
		<>
			<IngredientSeo viewModel={viewModel}/>
			<Layout>
				{/*<pre className="bg-white">{JSON.stringify(data, null, 4)}</pre>*/}
				<IngredientPageContent ingredientAggregate={viewModel}/>
			</Layout>
		</>
	)
}
