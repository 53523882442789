import {GatsbyImage, getImage, IGatsbyImageData} from "gatsby-plugin-image";
import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";

export interface IngredientImageOpenerProps {
	viewModel: IngredientAggregateViewModel
}

export default function IngredientImageOpener({viewModel}: IngredientImageOpenerProps) {
	const {t} = useI18next();
	const alt_text = t("Ingredients.Content.Opener.Image.alt", {name: viewModel.props.title})

	if (viewModel.props.images.opener.length > 0) {
		const imageEdge = viewModel.props.images.opener[0].node
		const imageData: IGatsbyImageData | undefined = getImage(imageEdge);
		return (
			<figure className="bg-slate-100 rounded my-5">
				{imageData && <GatsbyImage alt={alt_text} image={imageData} />}
			</figure>
		)
	} else {
		return (
			<div className="mt-8"></div>
		)
	}
}
