import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";
import {IngredientEntityViewModel} from "./ingredient-entity-view-model";
import _ from "lodash";

export type IngredientFishingAreaViewType = {
    locale: string
    fao_number: number | null
    area_main: string,
    area_sub: string | null
}

export interface IngredientSeasonViewModelProps {
    origin: string | null,
    remarks: string | null,
    entity: IngredientEntityViewModel | null,
    source: SourceViewModelNew | null,
    fishing_areas: IngredientFishingAreaViewType[]
    season: {
        january: number,
        february: number,
        march: number,
        april: number,
        may: number,
        june: number,
        july: number,
        august: number,
        september: number,
        october: number,
        november: number,
        december : number,
    }
}

export class IngredientSeasonViewModel extends ViewModel<IngredientSeasonViewModelProps> {

    private constructor(props: IngredientSeasonViewModelProps) {
        super(props);
    }

    public static create(props: IngredientSeasonViewModelProps): IngredientSeasonViewModel {
        return new IngredientSeasonViewModel({...props})
    }

    get fishing_area_main_names(): string[] {
        const areas = this.props.fishing_areas
            .map((area) => area.area_main)
            .sort()
        return _.uniq(areas)
    }

}

