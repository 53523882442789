import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";
import IngredientContentBodyParser from "../content/parser/ingredient-content-body-parser";

export interface IngredientContentTypesProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientContentTypes({viewModel}: IngredientContentTypesProps) {

    const {t, language} = useI18next();
    const localized_translation = viewModel.localized_translation(language);

    const body = localized_translation.props.content.types;
    const images = viewModel.props.images.content;

    if (body) {
        return (
            <>
                <IngredientContentBodyHeadingH2 id={"types-of-sushi"}>
                    {t("Ingredients.Content.Types.title", {name: viewModel.localized_title_with_common_name(language)})}
                </IngredientContentBodyHeadingH2>
                <IngredientContentBodyParser htmlInput={body} contentImageNodes={images}/>
            </>
        )
    } else {
        return (<></>)
    }
}