import {IngredientAggregateResponse} from "../../infrastructure/dto/ingrdient-graphql-response";
import {IngredientAggregateViewModel} from "../../presentation/model/ingredient-aggregate-view-model";
import {
    IngredientAggregateFactory
} from "../../presentation/factory/ingredient-aggregate-factory";

export class IngredientGraphResponseToViewModelUseCase {

    static execute(response: IngredientAggregateResponse): IngredientAggregateViewModel {
        return new IngredientAggregateFactory(response).execute();
    }
}