import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {I18nextContext, useI18next} from "gatsby-plugin-react-i18next";
import {CalendarIcon, RefreshIcon, UserIcon} from "@heroicons/react/outline";
import parse from "html-react-parser";

export interface IngredientAuthorDatesProps {
    viewModel: IngredientAggregateViewModel
}
export default function IngredientAuthorDates({viewModel}: IngredientAuthorDatesProps) {

    const options = {year: 'numeric', month: 'long', day: '2-digit'} as const;
    const {t, language} = useI18next();
    const localizedTranslation = viewModel.localized_translation(language);
    const publishedDate = localizedTranslation.props.dates.created;
    const updatedDate = localizedTranslation.props.dates.updated;

    const publishedDateLocalized = (): String  => {
        return new Date(publishedDate).toLocaleDateString(language, options);
    }

    const updatedDateLocalized = (): String | null => {
        if (updatedDate) {
            return new Date(updatedDate).toLocaleDateString(language, options);
        } else {
            return null
        }
    }

    const authors = (): JSX.Element | null => {
        const payload = localizedTranslation.props.authors;
        if (payload.length > 0) {
            const names = payload
                .map((author) => `${author.firstName} ${author.lastName}`)
                .join(", ")

            return (<span className="self-center">{names}</span>)
        } else {
            return null
        }
    }

    const Authors = ():JSX.Element  => {
        return (
            <>
                {authors() &&
                    <div className="text-xs flex gap-1 items-center">
                        <UserIcon className={"h-4"}/>
                        {authors()}
                    </div>
                }
            </>
        )
    }

    function DateInformation() {
        const created = publishedDateLocalized();
        const updated = updatedDateLocalized();

        return (
            <div className="flex gap-1">
                {/* Created */}
                <div className="flex flex-row gap-1 items-center">
                    <CalendarIcon className={"h-4"}/>
                    {/* Created Date */}
                    <span className="text-xs">{created} </span>
                </div>

                {/* Updated */}
                {updated &&
                    <div className="flex flex-row gap-1 items-center">
                        <RefreshIcon className={"h-4"}/>
                        <span className="text-xs">{updated} </span>
                    </div>}
            </div>
        )
    }

    return (
        <>
            <div className="my-4">
                <hr/>
                <div className="my-2">

                    <div className="flex flex-col sm:flex-row gap-1 justify-between">
                        <Authors/>
                        <DateInformation/>
                    </div>
                </div>
                <hr/>
            </div>
        </>
    )
}