import {AvailableLanguagesIngredient} from "../../infrastructure/dto/ingrdient-graphql-response";
import {IngredientAvailableLanguages} from "../model/ingredient-aggregate-view-model";

export class IngredientAvailableLanguagesFactory {
    private response: AvailableLanguagesIngredient;

    constructor(response: AvailableLanguagesIngredient) {
        this.response = response;
    }

    public execute(): IngredientAvailableLanguages[] {
        return this.response.translations.map((translation) => {
            return {
                locale: translation.languages_code.locale
            }
        })
    }

}