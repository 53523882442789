import {FishingAreasID, FishingAreasIDTranslation} from "../../infrastructure/dto/ingrdient-graphql-response";
import {IngredientFishingAreaViewType} from "../model/ingredient-season-view-model";

export class IngredientFishingAreaFactory {
    private readonly response: FishingAreasID;
    private readonly localized_translation: FishingAreasIDTranslation;

    constructor(response: FishingAreasID) {
        this.response = response;
        this.localized_translation = response.translations[0]; // Filtered by graphql query
    }

    public execute(): IngredientFishingAreaViewType {
        return {
            area_main: this.map_area_main(),
            area_sub: this.map_area_sub(),
            fao_number: this.map_fao_number(),
            locale: this.map_locale()
        }
    }

    private map_area_main(): string {
        return this.localized_translation.area_main;
    }

    private map_area_sub(): string | null {
        return this.localized_translation.area_sub;
    }

    private map_fao_number(): number | null {
        return this.response.fao_number;
    }

    private map_locale(): string {
        return this.localized_translation.languages_code.locale;
    }
}