import {FamilyTranslation} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientEntityFamilyCommonNameViewModel, IngredientEntityFamilyCommonNameViewModelProps
} from "../model/ingredient-entity-family-common-name-view-model";

export class IngredientEntityFamilyCommonNameFactory {
    private readonly response: FamilyTranslation;

    constructor(response: FamilyTranslation) {
        this.response = response;
    }

    public execute(): IngredientEntityFamilyCommonNameViewModel {
        const props: IngredientEntityFamilyCommonNameViewModelProps = {
            commonName: this.response.name,
            locale: this.response.languages_code.locale,
            script: this.response.script
        }
        return IngredientEntityFamilyCommonNameViewModel.create(props);
    }

}