import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {SourceCite} from "../../../../../../shared/source/presentation/component/source-cite";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";

export interface IngredientSourcesProps {
	viewModel: IngredientAggregateViewModel
}
export function IngredientSources({viewModel}: IngredientSourcesProps) {
	const {t} = useI18next();
	const sources_sorted = viewModel.props.sources.sort((a, b) => (a.props.short < b.props.short) ? -1:1);

	if (viewModel.props.sources.length > 0) {
		return (
			<>
				<IngredientContentBodyHeadingH2 id={"sources"}>
					{t("Ingredients.Sources.title")}
				</IngredientContentBodyHeadingH2>
				<ul className="list-disc pl-5 break-words">
					{sources_sorted.map((source, index) =>
						<li className="my-2" key={index}>
							<SourceCite key={index} addShort={true} viewModel={source}/>
						</li>
					)}
				</ul>
			</>
		)
	} else {
		return (
			<></>
		)
	}
}
