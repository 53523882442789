import {ViewModel} from "../../../../../shared/building_blocks/view_model";

export interface IngredientEntityFamilyCommonNameViewModelProps {
	locale: string,
	script: string,
	commonName: string
}

export class IngredientEntityFamilyCommonNameViewModel extends ViewModel<IngredientEntityFamilyCommonNameViewModelProps> {

	private constructor(props: IngredientEntityFamilyCommonNameViewModelProps) {
		super(props);
	}

	public static create(props: IngredientEntityFamilyCommonNameViewModelProps): IngredientEntityFamilyCommonNameViewModel {
		return new IngredientEntityFamilyCommonNameViewModel({...props})
	}

}
