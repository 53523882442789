import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import IngredientContentBodyParser from "../content/parser/ingredient-content-body-parser";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";

export interface IngredientContentBodyJapanProps {
	viewModel: IngredientAggregateViewModel
}

export default function IngredientContentBodySectionJapan({viewModel}: IngredientContentBodyJapanProps) {

	const {t, language} = useI18next();
	const localized_translation = viewModel.localized_translation(language);
	const body = localized_translation.props.content.in_japan;
	const images = viewModel.props.images.content
	//
	// const japanese_romaji_names = viewModel.japanese_main_entity_common_names(DomainIngredientScript.Latin)
	// 	.map((name) => {
	// 		return `${name} (${romaji_to_kana(name)})`;
	// 	});

	if (body) {
		return (
			<>
				<IngredientContentBodyHeadingH2 id={"definition"}>
					{t("Ingredients.Content.in_japan", {variable: viewModel.props.title})}
				</IngredientContentBodyHeadingH2>
				<div>
					<IngredientContentBodyParser htmlInput={body} contentImageNodes={images}/>
				</div>
				{/*<div>*/}
				{/*	<h3>Awabi Names in Japan</h3>*/}
				{/*	<p>{japanese_romaji_names.join(", ")}</p>*/}
				{/*</div>*/}
			</>
		)
	} else {
		return (<></>)
	}
}
