import {ViewModel} from "../../../../../shared/building_blocks/view_model";

export type IngredientImageDistributionMetaType = {
    common_names: {
        en: string | null
        es: string | null
        ja: string | null
        de: string | null
    },
    species: string
    source: string | null
}

export interface IngredientImageDistributionProps {
    imageEdge: any,
    meta: IngredientImageDistributionMetaType
}

export class IngredientImageDistributionViewModel extends ViewModel<IngredientImageDistributionProps> {

    private constructor(props: IngredientImageDistributionProps) {
        super(props);
    }

    public static create(props: IngredientImageDistributionProps): IngredientImageDistributionViewModel {
        return new IngredientImageDistributionViewModel({...props})
    }

    public localizeCommonName(locale: string): string | null {
        if (locale === "en") {
            return this.props.meta.common_names.en;
        }

        if (locale === "de") {
            return this.props.meta.common_names.de;
        }

        if (locale === "es") {
            return this.props.meta.common_names.es;
        }

        if (locale === "ja") {
            return this.props.meta.common_names.ja;
        }

        return null;
    }

}