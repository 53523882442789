import {Season} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientFishingAreaViewType,
    IngredientSeasonViewModel,
    IngredientSeasonViewModelProps
} from "../model/ingredient-season-view-model";
import {SourceViewModelFactory} from "../../../../../shared/source/application/mapper/from-graphql-to-view-model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";
import {IngredientEntityViewModel} from "../model/ingredient-entity-view-model";
import {IngredientEntityFactory} from "./ingredient-entity-factory";
import {IngredientFishingAreaFactory} from "./ingredient-fishing-area-factory";

export class IngredientSeasonsFactory {
    private readonly response: Season;

    constructor(response: Season) {
        this.response = response;
    }

    execute(): IngredientSeasonViewModel {
        const props: IngredientSeasonViewModelProps = {
            entity: this.map_entity(),
            origin: this.map_origin(),
            remarks: this.map_remarks(),
            source: this.map_source(),
            fishing_areas: this.map_fishing_areas(),
            season: {
                april: this.response.ingredient_season_id.april,
                august: this.response.ingredient_season_id.august,
                december: this.response.ingredient_season_id.december,
                february: this.response.ingredient_season_id.february,
                january: this.response.ingredient_season_id.january,
                july: this.response.ingredient_season_id.july,
                june: this.response.ingredient_season_id.june,
                march: this.response.ingredient_season_id.march,
                may: this.response.ingredient_season_id.may,
                november: this.response.ingredient_season_id.november,
                october: this.response.ingredient_season_id.october,
                september: this.response.ingredient_season_id.september
            }
        }
        return IngredientSeasonViewModel.create(props);
    }

    private map_entity(): IngredientEntityViewModel | null {
        const entity = this.response.ingredient_season_id.entity;

        if (entity) {
            return new IngredientEntityFactory(this.response.ingredient_season_id.entity).execute();
        } else {
            return null;
        }
    }

    private map_origin(): string | null {
        // filtered by graph query
        const translations = this.response.ingredient_season_id.translations;
        if (translations) {
            if (translations.length > 0) {
                return translations[0].origin
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private map_remarks(): string | null {
        // filtered by graph query
        const translations = this.response.ingredient_season_id.translations;
        if (translations) {
            if (translations.length > 0) {
                return translations[0].remarks
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private map_fishing_areas(): IngredientFishingAreaViewType[] {
        const areas = this.response.ingredient_season_id.fishing_areas;
        if (areas) {
            if (areas.length > 0) {
                return areas.map((area) => new IngredientFishingAreaFactory(area.fishing_areas_id).execute())
            } else {
                return []
            }
        } else {
            return []
        }
    }

    private map_source(): SourceViewModelNew | null {
        const source = this.response.ingredient_season_id.source;
        if (source) {
            return new SourceViewModelFactory(source).execute();
        } else {
            return null;
        }
    }


}