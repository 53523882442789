import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import {IngredientEntityViewModel} from "../../model/ingredient-entity-view-model";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";
import {IngredientEntityTable} from "./authentic_entities_table/shared/ingredient-entity-table";
import {columns} from "./authentic_entities_table/shared/inrgedient-entity-table-columns";
import React from "react";

export interface IngredientSectionEntitiesProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientSectionEntities({viewModel}: IngredientSectionEntitiesProps) {

    const {t, language} = useI18next();
    const authentic = viewModel.props.entity_authentic;
    const substitutes = viewModel.props.entity_substitute;


    function filtered_for_locale(input: IngredientEntityViewModel[]): IngredientEntityViewModel[] {
        return input.filter(entity => entity.props.commonNames
            .filter(name => name.props.locale === language))
            .filter(entity => entity.props.commonNames.some(name => name.props.locale === language))
    }

    function AuthenticTable() {
        if (authentic.length > 0) {
            return (
                <>
                    <h4>  {t("Ingredients.Content.Entities.authentic", {name: viewModel.props.title})}: </h4>
                    <IngredientEntityTable columns={columns} data={authentic}/>
                </>
            )
        } else {
            return (<></>)
        }
    }

    function SubstituteTable() {
        if (substitutes.length > 0) {
            return (
                <>
                    <h4>  {t("Ingredients.Content.Entities.substitute", {name: viewModel.props.title})}: </h4>
                    <IngredientEntityTable columns={columns} data={filtered_for_locale(substitutes)}/>
                </>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <>
            {/* Heading */}
            {(authentic.length > 0 || substitutes.length > 0) &&
                <IngredientContentBodyHeadingH2 id={"definition"}>
                    {t("Ingredients.Content.Entities.title", {name: viewModel.props.title})}
                </IngredientContentBodyHeadingH2>
            }

            {/* Tables */}
            <AuthenticTable/>
            <SubstituteTable/>
        </>
    )

}


