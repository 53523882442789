import {useI18next} from "gatsby-plugin-react-i18next";
import {IngredientEntityViewModel} from "../../../../model/ingredient-entity-view-model";
import React from "react";
import {
    build_common_names, build_family_common_names,
    build_kana_names, build_kanjis,
    build_romaji_names,
    buildSortedLabel
} from "./ingredient-entity-table-helper";

export const columns: any = [
    {
        id: 'japanese_name',
        Header: ({column}: any) => {
            const {t} = useI18next();
            return (
                <div className="p-3 text-xs sm:text-base">
                    {buildSortedLabel(t("Ingredients.Content.Entities.japanese_name"), column.isSorted, column.isSortedDesc)}
                </div>
            )
        },
        accessor: (originalRow: IngredientEntityViewModel, _: any) => build_romaji_names(originalRow),
        Cell: ({row}: any) => {
            return (
                <div className="p-3 text-sm sm:text-base">
                    <span className="font-mono">{build_romaji_names(row.original)}</span><br/>
                    <span className="font-light">{build_kana_names(row.original)}</span><br/>
                    <span className="font-light">{build_kanjis(row.original)}</span>
                </div>
            )
        }
    },
    {
        id: "common_names",
        Header: ({column}: any) => {
            const {t} = useI18next();
            return (
                <div className="p-3 text-xs sm:text-base">
                    {buildSortedLabel(t("Ingredients.Content.Entities.common_names"), column.isSorted, column.isSortedDesc)}
                </div>
            )
        },
        accessor: (originalRow: IngredientEntityViewModel, _: any) => originalRow.props.title,
        Cell: ({row}: any) => {
            const data: IngredientEntityViewModel = row.original;
            const {t} = useI18next();
            return (
                <div className="p-3">
                    <span className="text-sm sm:text-base">{build_common_names(row.original)}</span><br/>
                    <span className="text-xs sm:text-base font-light italic">{data.props.title}</span><br/>
                    <span className="text-xs sm:text-base font-light italic">
                        {t("Ingredients.Content.Entities.family_name")}: {build_family_common_names(row.original)}
                    </span><br/>
                </div>
            )
        }
    },
]
