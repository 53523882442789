import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {graphql, useStaticQuery} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";

interface VideoGraphResponse {
    content_video: ContentVideo;
}

interface ContentVideo {
    edges: Edge[];
}

interface Edge {
    node: Node;
}

interface Node {
    id: string;
    fileAbsolutePath: string;
    frontmatter: Frontmatter;
}

interface Frontmatter {
    title: string | null;
    creator: string | null;
    creator_url: string | null;
    provider: string | null;
    url: string;
    url_embed: string;
    audio_language_locale: string | null;
}


export interface IngredientContentVideoProps {
    viewModel: IngredientAggregateViewModel
}

export default function IngredientContentVideo({viewModel}: IngredientContentVideoProps) {

    const data: VideoGraphResponse = useStaticQuery(graphql`
        {
          content_video: allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/ingredients/*.*/video/"}}
          ) {
            edges {
              node {
                id
                fileAbsolutePath
                frontmatter {
                  title
                  creator
                  creator_url
                  provider
                  url
                  url_embed
                  audio_language_locale
                }
              }
            }
          }
        }
      `
    )

    const {t} = useI18next();
    const filtered_video = data.content_video.edges.filter((edge) => edge.node.fileAbsolutePath.includes(`${viewModel.props.slug}-video`))[0]

    if (filtered_video) {
        const creator = filtered_video.node.frontmatter.creator;
        const creator_url = filtered_video.node.frontmatter.creator_url;
        const title = filtered_video.node.frontmatter.title;
        const video_direct_url = filtered_video.node.frontmatter.url;
        const provider = filtered_video.node.frontmatter.provider;

        const urlOptions = {
            className: "text-salmonRed underline outbound break-words",
            aHrefRel: "external nofollow noopener noreferrer",
            target: "_blank",
        }

        const Cite = () => {
          return (
              <p className="my-2 text-xs sm:text-sm">
                  {t("Ingredients.Video.source", {provider: provider})}: <span>
                  {creator_url ?
                      (<a className={urlOptions.className} rel={urlOptions.aHrefRel} target={urlOptions.target} href={creator_url} >{creator}</a> ) :
                      (creator)
                  }</span>. <span className="break-words">
                      {<a className={urlOptions.className} rel={urlOptions.aHrefRel} target={urlOptions.target} href={video_direct_url} >{title}</a>}
                  </span>
              </p>
          )
        }

        return (
            <>
                <IngredientContentBodyHeadingH2 id={"video"}>
                    {t("Ingredients.Video.title", {name: viewModel.props.title})}
                </IngredientContentBodyHeadingH2>
                <div className="aspect-w-16 aspect-h-9">
                    <iframe className=""
                            data-name={"youtube"}
                            data-src={filtered_video.node.frontmatter.url_embed}
                            frameBorder="0"/>
                </div>
                <Cite/>
            </>
        )
    } else {
        return (<></>)
    }
}