import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import Masonry from "react-smart-masonry";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";
import SimpleReactLightbox from 'simple-react-lightbox'
import {SRLWrapper} from "simple-react-lightbox";

export interface IngredientGalleryProps {
	viewModel: IngredientAggregateViewModel
}

/*
 AT THE TIME OF DEVELOPING THIS THE FOLLOWING BETTER ALTERNATIVES WERE NOT REACT 17 READY
 CHECK IN FUTURE FOR:
 * https://www.npmjs.com/package/react-responsive-masonry
 * https://www.npmjs.com/package/react-snuggle
 */
export default function IngredientGallery({viewModel}: IngredientGalleryProps) {

	const {t, language} = useI18next();
	const images = viewModel.props.images.gallery.images;

	const options = {
		buttons: {
			showAutoplayButton: false,
			showCloseButton: true,
			showDownloadButton: false,
			showFullscreenButton: true,
			showNextButton: true,
			showPrevButton: true,
			showThumbnailsButton: false,
		}
	}

	const breakpoints = {mobile: 0, tablet: 768, desktop: 1024};


	if (images.length > 0) {
		return (
			<>
				<div>
					<IngredientContentBodyHeadingH2 id={"gallery"}>
						{t("Ingredients.Gallery.title")}
					</IngredientContentBodyHeadingH2>
					<SimpleReactLightbox>
						<SRLWrapper options={options}>
							<Masonry breakpoints={breakpoints}
									 columns={{
										 mobile: 2,
										 tablet: calculateTabletColumns(images.length),
										 desktop: calculateDesktopColumns(images.length)
									 }}
									 gap={{mobile: 2, tablet: 2, desktop: 2}}
							>
								{images.map((image, index) => {
										const imageData = getImage(image.props.image_edge_full.node);

									const altText = () => {
										const alt = image.getAlt(language);
										const cite = image.generateStringCite();

										if (alt) {
											return `${alt}. ${cite}`.replace("..", ".")
										} else {
											return cite;
										}
									}

									return (
											<div className="object-fill" key={image.props.context.original.title}>
												{imageData && <GatsbyImage key={index}
																		   alt={altText()}
                                                                           image={imageData}/>}
											</div>
										)
									}
								)}
							</Masonry>
						</SRLWrapper>
					</SimpleReactLightbox>
				</div>
				<div className="mb-5">
					<p className="text-2xs lg:text-sm mt-5">
						{t("Ingredients.Gallery.copyright_notice")}
					</p>
				</div>
			</>
		)
	} else {
		return (
			<></>
		)
	}
}


function calculateTabletColumns(actualLength: number): number {
	if (actualLength === 1) {
		return 1
	}
	if (actualLength === 2) {
		return 2
	}
	if (actualLength > 2) {
		return 3
	}

	return 0;
}


function calculateDesktopColumns(actualLength: number): number {
	if (actualLength === 1) {
		return 1
	}
	if (actualLength === 2) {
		return 2
	}
	if (actualLength > 2) {
		return 3
	}

	return 0;
}

