import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {IngredientCategory} from "./ingredient-category";
import {IngredientEntityViewModel} from "./ingredient-entity-view-model";
import {IngredientGalleryImageViewModel} from "./ingredient-gallery-image-view-model";
import {IngredientTranslationViewModel} from "./ingredient-translation-view-model";
import {IngredientWarningViewModel} from "./ingredient-warning-view-model";
import {titleCase} from "../../../../../shared/helper/title_case";
import {IngredientSeasonViewModel} from "./ingredient-season-view-model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";
import {IngredientImageDistributionViewModel} from "./ingredient-image-distribution";

export type IngredientImagesViewType = {
	opener: any[] // Array<edges>
	content: any[] // Array<edges>
	gallery: {
		images: IngredientGalleryImageViewModel[]
	}
	infobox: {
		illustration: any[] // Array<edges>
	}
	distribution_maps: IngredientImageDistributionViewModel[]
}

export type IngredientAvailableLanguages = {
	locale: string
}


export interface IngredientAggregateViewModelProps {
	slug: string
	available_languages: IngredientAvailableLanguages[]
	category: IngredientCategory
	collectiveTerm: string | null
	title: string
	translations: IngredientTranslationViewModel[]
	entity_main: IngredientEntityViewModel,
	entity_authentic: IngredientEntityViewModel[]
	entity_substitute: IngredientEntityViewModel[]
	images: IngredientImagesViewType
	sources: SourceViewModelNew[]
	warnings: IngredientWarningViewModel[]
	seasons: IngredientSeasonViewModel[]
}

export class IngredientAggregateViewModel extends ViewModel<IngredientAggregateViewModelProps> {

	private constructor(props: IngredientAggregateViewModelProps) {
		super(props);
	}

	public static create(props: IngredientAggregateViewModelProps): IngredientAggregateViewModel {
		return new IngredientAggregateViewModel({...props})
	}

	get available_translation_locales(): string[] {
		return this.props.available_languages.map((language) => language.locale)
	}

	public localized_translation(locale: string): IngredientTranslationViewModel {
		const localized = this.props.translations
		if (localized.length === 0) {
			throw Error(`No localized translation available for slug '${this.props.slug}' with locale '${locale}'`)
		}

		if (localized.length > 1) {
			throw Error(`More than one localized translation found for slug '${this.props.slug}' with locale '${locale}'`)
		}

		return localized[0]
	}

	public localized_title_with_common_name(locale: string): string {
		const commonName = this.localized_main_entity_common_name_preferred(locale);
		const title = this.props.title;
		return `${title} ${commonName ? `(${titleCase(commonName)})` : ""}`
	}

	public localized_authentic_common_names(locale: string): string[] {
		return this.props.entity_authentic
			.map((entity) => entity.props.commonNames
				.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
				.filter((name) => name.props.locale === locale)
				.map((filteredName) => filteredName.props.commonName))
			.flat()
	}

	public localized_main_entity_common_names(locale: string): string[] {
		return this.props.entity_main.props.commonNames
			.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
			.filter((name) => name.props.locale === locale)
			.map((filteredName) => filteredName.props.commonName)
	}

	public localized_main_entity_common_name_preferred(locale: string): string | undefined {
		return this.props.entity_main.props.commonNames
			.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
			.filter((name) => name.props.locale === locale)
			.filter((name) => name.props.isPreferred)
			.map((filteredName) => filteredName.props.commonName)[0]
	}

	// public japanese_main_entity_common_names(script: DomainIngredientScript): string[] {
	// 	return this.props.entity_main.props.commonNames
	// 		.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
	// 		.filter((name) => ((name.props.locale === "ja") && (name.props.script === script)))
	// 		.map((filteredName) => filteredName.props.commonName)
	// }

	public japanese_main_entity_kanji_preferred(): string | undefined {
		return this.props.entity_main.props.commonNames
			.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
			.filter((name) => ((name.props.locale === "ja") && (name.props.script === "kanji")))
			.filter((name) => (name.props.isPreferred))
			.map((filteredName) => filteredName.props.commonName)[0]
	}

	// public japanese_authentic_common_names(script: DomainIngredientScript): string[] {
	// 	return this.props.entity_authentic
	// 		.map((entity) => entity.props.commonNames
	// 			.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
	// 			.filter((name) => ((name.props.locale === "ja") && (name.props.script === script)))
	// 			.map((filteredName) => filteredName.props.commonName))
	// 		.flat()
	// }

	/**
	 * Returns the preferred authentic kanji.
	 * If none preferred is given, the first one will be returned.
	 */
	public japanese_entities_authentic_kanji(): string | null {
		return this.props.entity_authentic
			.map((entity) => entity.props.commonNames
				.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
				.sort((a, b) => (a.props.sort < b.props.sort) ? -1:1)
				.filter((name) => ((name.props.locale === "ja") && (name.props.script === "kanji")))
				.map((filteredName) => filteredName.props.commonName))
			.flat()[0]
	}

	public japanese_entity_main_kanjis(): string[] {
		return this.props.entity_main.props.commonNames
			.filter((name) => ((name.props.locale === "ja") && (name.props.script === "kanji")))
			.sort((a, b) => (a.props.isPreferred < b.props.isPreferred) ? -1:1)
			.map((kanji) => kanji.props.commonName)
	}

	public japanese_entities_authentic_kanjis(): string[] {
		return this.props.entity_authentic
			.map((entity) => entity.props.commonNames
				.sort((a, b) => (a.props.sort < b.props.sort) ? -1:1)
				.filter((name) => ((name.props.locale === "ja") && (name.props.script === "kanji")))
				.map((filteredName) => filteredName.props.commonName))
			.flat()
	}

	public localized_substitute_common_names(locale: string): string[] {
		return this.props.entity_authentic
			.map((entity) => entity.props.commonNames
				.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
				.filter((name) => name.props.locale === locale)
				.map((filteredName) => filteredName.props.commonName))
			.flat()
	}

	public localized_authentic_family_common_names(locale: string): string[] {
		return this.props.entity_authentic
			.map((entity) => {
				if (entity.props.family) {
					return entity.props.family.props.commonNames
						.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1 : 1)
						.filter((name) => name.props.locale === locale)
						.map((filteredName) => filteredName.props.commonName);
				} else {
					return []
				}
			})
			.flat()
	}

	public localized_main_entity_family_common_names(locale: string): string[] {
		if (this.props.entity_main.props.family) {
			return this.props.entity_main.props.family.props.commonNames
				.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
				.filter((name) => name.props.locale === locale)
				.map((filteredName) => filteredName.props.commonName)
		} else {
			return []
		}
	}

	public localized_substitute_family_common_names(locale: string): string[] {
		return this.props.entity_substitute
			.map((entity) => {
				if (entity.props.family) {
					return entity.props.family.props.commonNames
						.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1 : 1)
						.filter((name) => name.props.locale === locale)
						.map((filteredName) => filteredName.props.commonName);
				} else {
					return []
				}
			})
			.flat()
	}

	public localized_preferred_common_name_from_authentic_entites(locale: string): string | null {
		const result = this.props.entity_authentic
			.map((entity) => entity.props.commonNames
				.sort((a, b) => (a.props.commonName < b.props.commonName) ? -1:1)
				.filter((name) => ((name.props.locale === locale) && name.props.isPreferred) )
				.map((filteredName) => filteredName.props.commonName))
			.flat()
		if (result.length === 0) {
			return null;
		} else {
			return result[0];
		}
	}




}

function capitalize(s: string) {
	return s && s[0].toUpperCase() + s.slice(1);
}