import {EntitiesID} from "../../infrastructure/dto/ingrdient-graphql-response";
import {
    IngredientEntityViewModel,
    IngredientEntityViewModelProps
} from "../model/ingredient-entity-view-model";
import {
    IngredientEntityCommonNameViewModel, IngredientEntityCommonNameViewModelProps
} from "../model/ingredient-entity-common-name-view-model";
import {
    IngredientEntityFamilyViewModel,
    IngredientEntityFamilyViewModelProps
} from "../model/ingredient-entity-family-view-model";
import {IngredientEntityFamilyCommonNameFactory} from "./ingredient-entity-family-common-name-factory";

export class IngredientEntityFactory {
    private readonly response: EntitiesID;

    constructor(response: EntitiesID) {
        this.response = response;
    }

    public execute(): IngredientEntityViewModel {
        const props: IngredientEntityViewModelProps = {
            commonNames: this.map_common_names(),
            family: this.map_family(),
            title: this.map_title()
        }
        return IngredientEntityViewModel.create(props);
    }

    private map_title(): string {
        return this.response.title;
    }

    private map_common_names(): IngredientEntityCommonNameViewModel[] {
        return this.response.translations.map((entityTranslation) => {
            const props: IngredientEntityCommonNameViewModelProps = {
                commonName: entityTranslation.name,
                isPreferred: entityTranslation.is_preferred,
                locale: entityTranslation.languages_code.locale,
                script: entityTranslation.script,
                sort: entityTranslation.sort ?? 999
            }
            return IngredientEntityCommonNameViewModel.create(props);
        })
    }

    /**
     * Can return null because not every entity has an family. Like 'Superordo XYZ'
     * @private
     */
    private map_family(): IngredientEntityFamilyViewModel | null {
        if (this.response.family) {
            // filtered for language by query
            const familyNames = this.response.family.translations.map((translation) => {
                return new IngredientEntityFamilyCommonNameFactory(translation).execute();
            })
            const props: IngredientEntityFamilyViewModelProps = {
                commonNames: familyNames,
                title: this.response.family.title
            }
            return IngredientEntityFamilyViewModel.create(props);
        } else {
            return null;
        }
    }
}