import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {IngredientAggregateViewModel} from "../../model/ingredient-aggregate-view-model";
import {IngredientGalleryImageCiteBuilder} from "./ingredient-gallery-image-cite-builder";
import IngredientContentBodyHeadingH2 from "../content/ingredient-content-body-heading-h2";

export interface IngredientGalleryImageCreditsProps {
	viewModel: IngredientAggregateViewModel
}

export function IngredientGalleryImageCredits({viewModel}: IngredientGalleryImageCreditsProps) {
	const {t} = useI18next();

	if (viewModel.props.images.gallery.images.length > 0) {
		return (
			<>
				<div>
					<IngredientContentBodyHeadingH2 id={"image-credits"}>
						{t("Ingredients.Gallery.Sources.title")}
					</IngredientContentBodyHeadingH2>
					<ol className="list-disc pl-4">
						{viewModel.props.images.gallery.images.map((image, index) => {
							return (
								<li className="my-2" key={index}>
									<p key={index}>
										<IngredientGalleryImageCiteBuilder image={image} key={index}/>
									</p>
								</li>
							)
						})}
					</ol>
				</div>
			</>
		)
	} else {
		return (<></>)
	}
}
