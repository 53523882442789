import {IngredientCategory} from "../model/ingredient-category";

export class IngredientCategoryFactory {

    public static create_from_string(str: string) {
        if (str === "fish") {
            return IngredientCategory.Fish
        }
        if (str === "crustacean") {
            return IngredientCategory.Crustacean
        }
        if (str === "cephalopod") {
            return IngredientCategory.Cephalopod
        }
        if (str === "bivalvia") {
            return IngredientCategory.Bivalvia
        }
        if (str === "snail_or_slug") {
            return IngredientCategory.SnailOrSlug
        }
        if (str === "vegetable_or_fruit") {
            return IngredientCategory.VegetableOrFruit
        }
        if (str === "roe") {
            return IngredientCategory.Roe
        }
        if (str === "other") {
            return IngredientCategory.Other
        }

        throw new Error(`Could not map value '${str}' to IngredientCategory.`)
    }
}