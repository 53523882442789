import {ViewModel} from "../../../../../shared/building_blocks/view_model";


export type IngredientAuthorViewType = {
	firstName: string,
	lastName: string,
	title: string | null,
	email: string | null
}

export type IngredientDateViewType = {
	created: Date,
	updated: Date | null
}

export type IngredientMetaViewType = {
	description: string
}

export type IngredientTranslationContentViewType = {
	definition: string
	as_ingredient_for_sushi_sashimi: string
	types: string | null
	characteristics_and_ecology: string | null
	economy: string | null
	in_japan: string | null
	trivia: string | null
}

export interface IngredientTranslationViewModelProps {
	dates: IngredientDateViewType,
	locale: string,
	content: IngredientTranslationContentViewType,
	authors: IngredientAuthorViewType[]
	meta: IngredientMetaViewType
}

export class IngredientTranslationViewModel extends ViewModel<IngredientTranslationViewModelProps> {

	private constructor(props: IngredientTranslationViewModelProps) {
		super(props);
	}

	public static create(props: IngredientTranslationViewModelProps): IngredientTranslationViewModel {
		return new IngredientTranslationViewModel({...props})
	}
}
