export enum IngredientCategory {
	Fish = "fish",
	Crustacean = "crustacean",
	Cephalopod = "cephalopod",
	Bivalvia = "bivalvia",
	SnailOrSlug = "snail_or_slug",
	VegetableOrFruit = "vegetable_or_fruit",
	Roe = "roe",
	Other = "other"
}

